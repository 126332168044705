import React from 'react';
import DefaultLayout from '../layouts';
import { get } from 'lodash';
import { generateLayout, generateInvisibleComponents, getComponents, getInvisibleComponents } from 'helpers';
function LandingPageTemplate(props: any) {
    const components = getComponents(get(props, 'pageContext.data.components', []))
    const invisibleComponents = getInvisibleComponents(get(props, 'pageContext.data.invisibleComponents', []))
    const breadcrumbs = get(props, 'pageContext.data.breadcrumbs', [])

    if (process.env.NODE_ENV !== 'production') {
        console.log('props', props)
        console.log('components', components)
        console.log('invisibleComponents', invisibleComponents)
    }

    return (
        <DefaultLayout {...props}>
            {generateLayout(components, breadcrumbs)}
            {generateInvisibleComponents(invisibleComponents)}
        </DefaultLayout>
    )
}

export default LandingPageTemplate